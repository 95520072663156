.footer-container {
    /* background-color: rebeccapurple; */
    padding: 40px 20px !important;
}

.footer-row-one {
    display: flex;
    flex-wrap: wrap;
}

.mobile-footer-one{
    display: none;
}

.footer-company-details-container {
    /* background-color: lightseagreen; */
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.footer-company-logo-container {
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-company-logo-container img {
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
}

.footer-company-details {
    /* background-color: red; */
    padding-top: 5px;
}

/* .footer-company-details p {} */

.footer-app-download-btn-container {
    /* background-color: tomato; */
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}

.footer-app-download-btn-container img {
    width: 150px;
    border-radius: 5px;
    transition: all 0.5s ease-out;
}

.footer-app-download-btn-container img:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.footer-links-container {
    /* background-color: lightblue; */
    display: flex;
    padding: 5px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.footer-links-container-one {
    /* background-color: lightcoral; */
    width: 47%;
}

.footer-links-container-two {
    /* background-color: lightgreen; */
    width: 47%;
}

/* .footer-page-links {
    margin-bottom: 10px
    background-color: lightsalmon;
} */


.footer-car-list-card-container {
    /* background-color: lightcoral; */
    display: flex;
    justify-content: start;
    align-items: center;
}

.footer-car-list-card {
    /* background-color: yellow; */
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    /* display: none; */
}

.footer-car-list-card-img-container {
    /* background-color: lightcyan; */
    width: 40%;
}

.footer-car-list-card-img {
    height: 100px;
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all 0.5s ease-out;
}
.footer-car-list-card-img:hover{
    opacity: 0.5;
}

.footer-car-list-card-details-container {
    /* background-color: lightpink; */
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .footer-car-list-card-details {} */

.footer-car-list-card-details h6 {
    /* margin-bottom: 0px; */
    font-size: 14px;
}

.footer-car-list-btn-container {
    /* background-color: yellow; */
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 15px;
}

.footer-car-list-btn-container button {
    transition: all 0.5s ease-out;
}

.footer-car-list-btn-container button:hover {
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.footer-card-add-circle {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: absolute;
    border-radius: 50%;
    padding: 0px 5px;
    top: -10px;
    left: 135px;
    cursor: pointer;
}

.footer-contact-details-container-parent{
    width: auto;
}

.footer-location-details-container-parent{
    width: auto;
}

.footer-links-container-parent{
    width: auto;
}

.footer-company-details-container-parent{
    width: 100%;
}

.footer-contact-details-container {
    padding: 5px;
    /* width: fit-content; */
}
.links {
    transition: all 0.5s ease-out;
    cursor: pointer;
  }
  
  .links:hover {
    /* color: #3f71b9; */
    text-decoration: underline;
  }
  
  .text-line {
    text-decoration: 1px line-through solid black;
  }
  .text-gray {
    color: gray !important;
  }
  
  .page-links {
    transition: all 0.5s ease-out;
    cursor: pointer;
    color: white;
    padding: 0.5rem 0 0.5rem 0;
    text-decoration: none;
    display: block;
  }
  
  .page-links:hover {
    color: #3f71b9 !important;
    text-decoration: underline;
  }

.footer-links-icons {
    height: 15px;
    width: 15px;
    margin-right: 7px;
}

.footer-contact-details {
    /* background-color: lightpink; */
    /* margin-bottom: 25px; */
}

.footer-location-details-container {
    padding: 5px;
    /* background-color: lightsteelblue; */
}


.footer-map-container {
    /* background-color: lightskyblue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-row-two {
    /* background-color: lightblue; */
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-payment-cards-container {
    /* background-color: yellow; */
    display: flex;
}

.footer-payment-cards {
    padding: 10px;
    /* background-color: lightgoldenrodyellow; */
}

.footer-language-links-container {
    /* background-color: antiquewhite; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    gap: 20px;
}

.footer-social-media-icons-container {
    /* background-color: lightcoral; */
    display: flex;
    flex-wrap: wrap;
}

.footer-social-media-icons-container img {
    background-color: whitesmoke;
    margin: 10px;
    padding: 7px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    transition: all 0.5s ease-out;
}

.footer-social-media-icons-container img:hover {
    transform: scale(1.5);
}

@media screen and (max-width:1550px) {
    /* .footer-row-one{
        flex-direction: column;
    } */
}

@media screen and (max-width:500px) {
    .footer-links-container{
        gap: 10px;
    }
}

@media screen and (max-width:1280px) {
    .footer-row-one{
        display: none;
    }
    .mobile-footer-one{
        display: flex;
    }
    .footer-contact-details-container{
        flex-wrap: wrap;
    }
}



